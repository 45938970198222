import type { HeadlineResponse } from '@on3/api';
import { On3IconLockPremium } from '@on3/icons';
import { toFriendlyDate } from '@on3/ui-lib/src/utils/date';
import clsx from 'clsx';
import { ResizedImage } from 'components/Image/ResizedImage';
import { TextLink } from 'components/UI/Link/TextLink';
import { Text } from 'components/UI/Typography/Text';
import Link from 'next/link';

import styles from './ArticleFeed.module.scss';

interface IArticleFeedProps extends HeadlineResponse {
  primaryColor?: string;
}
export const ArticleFeed = ({
  featuredImage,
  title,
  fullUrl,
  author,
  postDate,
  isPremium,
  primaryCategory,
  primaryColor,
}: IArticleFeedProps) => {
  let baseUrl = primaryCategory?.fullUrl;
  const hideCategory = primaryCategory?.key === 557908;

  if (baseUrl?.endsWith('/')) {
    baseUrl = baseUrl.slice(0, -1);
  }

  return (
    <article className={styles.container}>
      {featuredImage && (
        <TextLink href={fullUrl}>
          <ResizedImage
            {...featuredImage}
            className={styles.image}
            fit="cover"
            gravity="0.5x0.5"
            height={100}
            quality={90}
            width={160}
          />
        </TextLink>
      )}

      <div className={styles.text}>
        {primaryCategory?.name && !hideCategory && (
          <TextLink
            className={styles.category}
            href={`${baseUrl}/news/`}
            style={{ color: primaryColor }}
          >
            {primaryCategory?.name}
          </TextLink>
        )}
        <TextLink href={fullUrl}>
          <Text className={styles.title} component="h3" variant="h6">
            {title}
          </Text>
        </TextLink>

        <div
          className={clsx(styles.authorblock, {
            [styles.isPremiumMobile]: isPremium,
          })}
        >
          <Link className={styles.name} href={`/user/${author?.niceName}/`}>
            {author?.name}
          </Link>
          <span className={styles.date}>
            <span
              className={clsx(styles.bullet, {
                [styles.isPremiumBullet]: isPremium,
              })}
            >
              &#8226;
            </span>
            {toFriendlyDate(postDate)}
          </span>
          {isPremium && <On3IconLockPremium className={styles.on3Icon} />}
        </div>
      </div>
    </article>
  );
};
