import type { NilHundredResponse } from '@on3/api';
import {
  formatCurrency,
  formatMillions,
} from '@on3/ui-lib/src/utils/formatters';
import { SportIcon } from 'components/Icons/SportIcon';
import { On3Avatar } from 'components/Image/On3Avatar';
import { Link } from 'components/Link';

import {
  divisionAbbreviation,
  getAbbreviatedRank,
  getNILProfileLink,
} from './helpers';
import styles from './Nil100WidgetItems.module.scss';

interface Nil100WidgetItemsProps extends NilHundredResponse {
  indexRank: number;
  gender?: 'mens' | 'womens';
}

export const Nil100WidgetItems = ({
  person,
  valuation,
  indexRank,
  gender,
}: Nil100WidgetItemsProps) => {
  if (!person) {
    return null;
  }

  const {
    commitStatus,
    defaultAsset,
    division,
    name,
    defaultSport,
    positionAbbreviation,
  } = person;

  const profileLink = getNILProfileLink({
    sport: person?.defaultSport?.name,
    slug: person?.slug,
  });

  const rank = gender === 'womens' ? indexRank : valuation?.rank;

  return (
    <li className={styles.block}>
      <span className={styles.rank}>{rank}</span>

      <On3Avatar
        altText={defaultAsset?.title ?? 'player headshot'}
        className={styles.avatar}
        domain={defaultAsset?.domain}
        height={50}
        source={defaultAsset?.source}
        title={defaultAsset?.title ?? ''}
        width={50}
      />

      <div className={styles.meta}>
        <div className={styles.divisionPosition}>
          {division && <span>{divisionAbbreviation(division ?? 'NA')}</span>}
          <SportIcon sportName={defaultSport?.name} />
          <span>{getAbbreviatedRank(commitStatus?.classRank ?? '')}</span>
        </div>

        <div>
          <Link className={styles.name} href={profileLink} useSiteColor>
            {name}
          </Link>
        </div>

        <span className={styles.position}>{positionAbbreviation}</span>
      </div>

      <div className={styles.stats}>
        <span className={styles.valuation}>
          {valuation?.valuation ? formatCurrency(valuation?.valuation) : '-'}
        </span>
        <span className={styles.followers}>
          <span>
            {valuation?.followers ? formatMillions(valuation?.followers) : '-'}
            <br />
            followers
          </span>
        </span>
      </div>
    </li>
  );
};
