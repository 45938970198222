import styles from './SideModuleWrapper.module.scss';

interface ISideModuleWrapperProps {
  className?: string;
  children: React.ReactNode;
  title: string;
}

export const SideModuleWrapper = ({
  className,
  children,
  title,
}: ISideModuleWrapperProps) => {
  return (
    <section className={className}>
      <header>
        <h2 className={styles.title}>{title}</h2>
      </header>
      {children}
    </section>
  );
};
