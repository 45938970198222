import * as React from 'react';
import { SVGProps } from 'react';

const SvgLockPremium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#29F085"
      d="M15.007 7.037h-.847V5.344a4.234 4.234 0 0 0-4.233-4.233 4.234 4.234 0 0 0-4.233 4.233v1.693h-.846c-.931 0-1.693.762-1.693 1.693v8.466c0 .931.762 1.693 1.693 1.693h10.159c.93 0 1.693-.762 1.693-1.693V8.73c0-.93-.762-1.693-1.693-1.693ZM7.303 5.344A2.627 2.627 0 0 1 9.927 2.72a2.627 2.627 0 0 1 2.625 2.624v1.693h-5.25V5.344Z"
    />
    <path fill="#fff" d="M13.771 13.382H6.229v-.838h7.542z" />
    <path fill="#fff" d="M10.42 9.192v7.542H9.58V9.192z" />
  </svg>
);
export default SvgLockPremium;
